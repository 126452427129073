import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import JPlayer, { SeekBar, BufferBar, PlayBar, Play } from 'react-jplayer'
import moment from 'moment-timezone'

class MiniPlayer extends Component {
  onClick() {
    const {  playingChannel, selectedEpisode, history } = this.props

    let formatedDate = moment(selectedEpisode.date).tz('utc').format('MMDDYYYY')

    if (selectedEpisode.type === 'reading') {
      return history.push(`/${playingChannel.key}/${formatedDate}` )   
    } else {
      const prefix = selectedEpisode.title.toLowerCase().includes('community') ? 'community' : 'other'
      return history.push(`/${playingChannel.key}/${prefix}${formatedDate}`)
    }
  }

  render() {
    const { player, hidden,  playingChannel, playingEpisode } = this.props

    if (!playingEpisode || !playingChannel) {
      return <div></div>
    }

    return (
      <JPlayer id={player.id}>
        <div className={`mini-player ${hidden ? "hidden" : ""}`}>
          <SeekBar>
            <BufferBar />
            <PlayBar />
          </SeekBar>
          <div className="mini-player__details">
            <Play><i className={`details__controls fa mdi ${player.paused ? "mdi-play" : "mdi-pause"}`}></i></Play>
            <p className="details__title" onClick={() => this.onClick()}>{playingEpisode.title}</p>
            <p className="details__channel" onClick={() => this.onClick()}>{playingChannel.title}</p>
            <button 
              className="details__expand mdi mdi-arrow-expand-up"
              onClick={() => this.onClick()}  
            ><span className="acc-btn">Expand</span></button>
            <div className="details__bg"></div>
          </div>
        </div>
      </JPlayer>
    )
  }
}

const mapStateToProps = state => {
  return { 
    player: state.jPlayers.DailyAudioBiblePlayer
  }
}

export default connect(mapStateToProps)(withRouter(MiniPlayer))
