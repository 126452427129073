import React, { Component } from 'react'
import Player from './Player'
import Spinner from './Spinner'

class PlayerView extends Component {
  renderPlayer() {
    const { selectedEpisode, playingChannel, url, queryDate, setPlayerEpisode } = this.props

    if (!selectedEpisode || !playingChannel) {
      return <Spinner />
    }

    return (
      <Player 
        selectedEpisode={selectedEpisode}
        playingChannel={playingChannel}
        url={url}
        queryDate={queryDate}
        setPlayerEpisode={setPlayerEpisode}
      />
    )
  }

  render() {
    const { showPlayer } = this.props

    return (
      <div className={showPlayer ? "player-view" : "hidden"}>
        <div className="container">
          {this.renderPlayer()}
        </div>
      </div>
    )
  }
}

export default PlayerView
