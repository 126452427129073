import { Component } from 'react'
import { actions } from 'react-jplayer'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { GET_EPISODES } from '../queries'
import moment from 'moment-timezone'


class WindowAPI extends Component {

    play(props) {
        
        const { player, dispatch } = props

        if (player.paused) {
            dispatch(actions.play("DailyAudioBiblePlayer"))
        } else {
            dispatch(actions.pause("DailyAudioBiblePlayer"))
        }
    }


    next(props) {

        const { client, queryDate, channel, episode, setPlayerEpisode, history, match } = props

        try {
            var data = client.readQuery({
                query: GET_EPISODES,
                variables: {
                    episodeDate: queryDate,
                    cursor: null,
                    channelId: channel.channelId
                }
            })

            for (let i = 0; i < data.episodes.edges.length; i++) {
                if (data.episodes.edges[i].episodeId === episode.episodeId && i > 0) {

                        const newEpisode = data.episodes.edges[i-1]

                        let url = `/${channel.key}/`

                        let formatedDate = moment(newEpisode.date).tz('utc').format('MMDDYYYY')
                    
                        if (newEpisode.type === 'reading') {
                            url += formatedDate    
                        } else {
                            const prefix = newEpisode.title.toLowerCase().includes('community') ? 'community' : 'other'
                            url += `${prefix}${formatedDate}`
                        }

                        setPlayerEpisode(newEpisode, false)

                        if (match.params.date) {
                            history.push(url)
                        }
                    break
                }
            }

        } catch (error) {
            console.log(error)
        }
    }


    previous(props) {

        const { client, queryDate, channel, episode, setPlayerEpisode, history, match } = props

        try {
            var data = client.readQuery({
                query: GET_EPISODES,
                variables: {
                    episodeDate: queryDate,
                    cursor: null,
                    channelId: channel.channelId
                }
            })

            for (let i = 0; i < data.episodes.edges.length; i++) {
                if (data.episodes.edges[i].episodeId === episode.episodeId && i < data.episodes.edges.length-1) {
                    const newEpisode = data.episodes.edges[i+1]

                    let url = `/${channel.key}/`

                    let formatedDate = moment(newEpisode.date).tz('utc').format('MMDDYYYY')
                
                    if (newEpisode.type === 'reading') {
                        url += formatedDate     
                    } else {
                        const prefix = newEpisode.title.toLowerCase().includes('community') ? 'community' : 'other'
                        url += `${prefix}${formatedDate}`
                    }

                    setPlayerEpisode(newEpisode, false)

                    if (match.params.date) {
                        history.push(url)
                    }
                    break
                }
            }

        } catch (error) {
            console.log(error)
        }
    }


    seekForward(props) {

        const { player, dispatch } = props
        const newTime = player.currentTime + 30
        
        if (player.paused) {
            dispatch(actions.pause("DailyAudioBiblePlayer", newTime))
        } else {
            dispatch(actions.play("DailyAudioBiblePlayer", newTime))
        }
    }


    seekBackward(props) {

        const { player, dispatch } = props
        const newTime = player.currentTime - 30
        
        if (player.paused) {
            dispatch(actions.pause("DailyAudioBiblePlayer", newTime))
        } else {
            dispatch(actions.play("DailyAudioBiblePlayer", newTime))
        }
    }


    volumeUp(props) {

        const { player, dispatch } = props

        if ( player.volume < 1) {
            const newVolume = player.volume + .05

            dispatch(actions.setVolume('DailyAudioBiblePlayer', newVolume))
        }
    }


    volumeDown(props) {

        const { player, dispatch } = props

        if ( player.volume > 0) {
            const newVolume = player.volume - .05

            dispatch(actions.setVolume('DailyAudioBiblePlayer', newVolume))
        }
    }


    componentDidMount() {

        window['lutd-api'] = [
            'lutd-play',
            'lutd-next',
            'lutd-previous',
            'lutd-seekforward',
            'lutd-seekbackward',
            'lutd-volumeup',
            'lutd-volumedown'
        ]

        // Window Event API
        window.addEventListener('lutd-play', () => this.play(this.props))
        window.addEventListener('lutd-next', () => this.next(this.props))
        window.addEventListener('lutd-previous', () => this.previous(this.props))
        window.addEventListener('lutd-seekforward', () => this.seekForward(this.props))
        window.addEventListener('lutd-seekbackward', () => this.seekBackward(this.props))
        window.addEventListener('lutd-volumeup', () => this.volumeUp(this.props))
        window.addEventListener('lutd-volumedown', () => this.volumeDown(this.props))

        // Send play and pause events
        window.addEventListener('lutd-player-ready', () => {
            document.querySelector('.jp-media').addEventListener('play', () => window.dispatchEvent( new Event('lutd-playing') ))
            document.querySelector('.jp-media').addEventListener('pause', () => window.dispatchEvent( new Event('lutd-paused') ))
        })
    }


    render() {

        // Return the application
        return this.props.children
    }
}

const mapStateToProps = ({ jPlayers }) => {
    return { player: jPlayers.DailyAudioBiblePlayer }
}

export default withRouter(withApollo(connect(mapStateToProps)(WindowAPI)))