import React from 'react'
import { Link } from 'react-router-dom'

const Channel = ({ channels, setChannel }) => {
    return channels.map((channel) => {
        const { channelId, key, imageURL } = channel        
        return (
            <div 
                key={channelId}
                onClick={() => setChannel(channel)}
            >
                <Link to={`/${key}`}>
                    <img alt={channelId} src={imageURL} />
                </Link>
            </div>
        )    
    })
}


export default Channel