import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import Episode from './Episode'

class EpisodeList extends Component {
  
  // all retracted logic is for the '<- Channels' button.
  constructor(props) {
    super(props)

    this.onResize = this.onResize.bind(this)
    this.state = ({ 
      windowWidth: window.innerWidth, 
      retracted: false, 
      interval: null,
      throttle: _.throttle(this.onResize, 200, { 'trailing': false })  
    })
  }

  onResize() {
    this.setState({ windowWidth: window.innerWidth })

    if (this.state.windowWidth < 900) {
      this.setState({ retracted: true })
    } else {
      this.setState({ retracted: false })
    }
  }

  // including this stuff.
  componentDidMount() {
    if (this.state.windowWidth < 900) {
      this.setState({ retracted: true })
    }
   
    window.addEventListener('resize', this.state.throttle) 
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.state.throttle)
  }

  // For desktop view
  renderBackButton() {
    if (!this.state.retracted) {
      return (
        <div className="player__back">
          <Link className="mdi mdi-arrow-left" to="/">Channels</Link>
        </div>
      )
    }
  }
  
  render() {
    const { episodes, selectedChannel, setPlayerEpisode } = this.props


    if (!selectedChannel) {
      return <div />
    }

    return (
      <div className={`episodes ${this.state.retracted === false ? "scrollbar-hidden" : ""}`}>
        <div className="container">
          {this.renderBackButton()}
          <h1>{selectedChannel.title} Episodes</h1>
          <div className="episode__list">
            <Episode 
              episodes={episodes} 
              selectedChannel={selectedChannel} 
              setPlayerEpisode={setPlayerEpisode}
            />
          </div>
        </div>
      </div>
    )
  }
}


export default EpisodeList