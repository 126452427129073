import gql from 'graphql-tag'

export const GET_CHANNELS = gql`
  {
    channels {
      id
      channelId
      imageURL
      key
      title
      rolloverMonth
      rolloverDay
      bufferPeriod
      bufferLength
    }
  }
`

export const GET_EPISODES = gql`
    query($episodeDate: DateTime!, $channelId: Int!, $cursor: String) {
        episodes (date: $episodeDate, channelId: $channelId, cursor: $cursor) {
            edges {
                channelId
                episodeId
                title
                date
                description
                type
                id
                audioURL
                readTranslationShort
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
`

export const CHANNEL_SUBSCRIPTION = gql`
    subscription ChannelSubscription {
      channelPublished {
        channel {
          id
          channelId
          imageURL
          key
          title
          rolloverMonth
          rolloverDay
          bufferPeriod
          bufferLength
        }    
      }
    }
`

export const EPISODE_SUBSCRIPTION = gql`
    subscription EpisodeSubscription {
      episodePublished {
        episode {
          channelId
          episodeId
          title
          date
          description
          type
          id
          audioURL
          readTranslationShort
        }
      }
    }
`
