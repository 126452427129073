import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { withApollo } from 'react-apollo'
import JPlayer, {
  initializeOptions, Gui, SeekBar, BufferBar,
  Audio, Title, Play, PlayBar, Duration, VolumeBar, VolumeBarValue,
  Mute, CurrentTime, BrowserUnsupported, actions
} from 'react-jplayer'
import { GET_EPISODES } from '../queries'
import ShareModal from './ShareModal'
import moment from 'moment-timezone'

const defaultOptions = {
  id: 'DailyAudioBiblePlayer',
  keyEnabled: true,
  media: {
    title: '',
    artist: '',
    sources: {
      m4a: ''
    },
  },
  autoplay: true,
  free: true,
  verticalVolume: false
}

initializeOptions(defaultOptions)

class Player extends Component {

  constructor(props) {
    super(props)
    
    this.state = { shareModalOpen: false }
  }


  componentDidMount() {
    if (/Trident.*rv[ :]*(\d+\.\d+)/.test(navigator.userAgent)) {
      return
    } else {
      window.dispatchEvent( new Event('lutd-player-ready') )
    }
  }


  // logic for a reusable player
  renderBibleGatewayLink() {
    const { playingChannel, selectedEpisode } = this.props

    let formatedDate = moment(selectedEpisode.date).tz('utc').format('YYYY/MM/DD')
  
    // LINK ONLY WORKS FOR DAB CHANNEL
    const link = `https://www.biblegateway.com/reading-plans/daily-audio-bible/${formatedDate}?version=${selectedEpisode.readTranslationShort}`

    if (playingChannel.key === "dab" && selectedEpisode.type === 'reading') {
      return <a target="_blank" href={link} rel="noopener noreferrer">Read on Bible Gateway</a>
    }
  }


  renderVolumeBar() {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

    if (iOS) {
      return 
    } 
      return (
        <div className="jp-volume-bar-wrap">    
          <VolumeBar>
            <VolumeBarValue />
          </VolumeBar>
        </div>
      )

    
  }


  nextEpisode() {

    const { client, queryDate, playingChannel, selectedEpisode, setPlayerEpisode, history } = this.props

    try {
      var data = client.readQuery({
        query: GET_EPISODES,
        variables: {
          episodeDate: queryDate,
          cursor: null,
          channelId: playingChannel.channelId
        }
      })

      for (let i = 0; i < data.episodes.edges.length; i++) {
        if (data.episodes.edges[i].episodeId === selectedEpisode.episodeId && i > 0) {

          const newEpisode = data.episodes.edges[i-1]

          setPlayerEpisode(newEpisode, false)

          let url = `/${playingChannel.key}/`

          if (window.location.pathname.split('/').length === 3) {
            let formatedDate = moment(newEpisode.date).tz('utc').format('MMDDYYYY')
      
            if (newEpisode.type === 'reading') {
              url += formatedDate    
            } else {
              const prefix = newEpisode.title.toLowerCase().includes('community') ? 'community' : 'other'
              url += `${prefix}${formatedDate}`
            }

            history.push(url)
          }
          break
        }
      }
    } catch (error) {
      console.log(error)
    }
  }


  previousEpisode() {

    const { client, queryDate, playingChannel, selectedEpisode, setPlayerEpisode, history } = this.props

    try {
      var data = client.readQuery({
        query: GET_EPISODES,
        variables: {
          episodeDate: queryDate,
          cursor: null,
          channelId: playingChannel.channelId
        }
      })

      for (let i = 0; i < data.episodes.edges.length; i++) {
        if (data.episodes.edges[i].episodeId === selectedEpisode.episodeId && i < data.episodes.edges.length-1) {

          const newEpisode = data.episodes.edges[i+1]

          setPlayerEpisode(newEpisode, false)

          if (window.location.pathname.split('/').length === 3) {
            let url = `/${playingChannel.key}/`

            let formatedDate = moment(newEpisode.date).tz('utc').format('MMDDYYYY')
        
            if (newEpisode.type === 'reading') {
              url += formatedDate    
            } else {
              const prefix = newEpisode.title.toLowerCase().includes('community') ? 'community' : 'other'
              url += `${prefix}${formatedDate}`
            }

            history.push(url)
          }
          break
        }
      }
    } catch (error) {
      console.log(error)
    }
  }


  // Jump forward 30 seconds
  jumpForward() {
    const { player, dispatch } = this.props
    const newTime = player.currentTime + 30

    if (player.paused) {
      dispatch(actions.pause("DailyAudioBiblePlayer", newTime))
    } else {
      dispatch(actions.play("DailyAudioBiblePlayer", newTime))
    }
  }


  // Jump backward 30 seconds
  jumpBackward() {
    const { player, dispatch } = this.props
    const newTime = player.currentTime - 30

    if (player.paused) {
      dispatch(actions.pause("DailyAudioBiblePlayer", newTime))
    } else {
      dispatch(actions.play("DailyAudioBiblePlayer", newTime))
    }
  }


  // out-of-the-box jplayer
  render() {
    const { selectedEpisode, playingChannel, url, player } = this.props

    return (
      <div className="player">
        <ShareModal
          url={url}
          shareModalOpen={this.state.shareModalOpen}
          closeModal={() => this.setState({ shareModalOpen: false })}
        />
        <div className="modal-helper" onClick={() => this.setState({ shareModalOpen: false })} />
        <JPlayer id={defaultOptions.id} className="jp-sleek">
          <Audio />
          <Gui>

            <div className="jp-image">
              <img alt={playingChannel.imageURL} src={playingChannel.imageURL} />
            </div>

            <div className="jp-title-container">
              <h3><Title /></h3>
              <p>{selectedEpisode.description}</p>
            </div>

            <div className="jp-progress">
              <div className="jp-seek-bar-wrap">
                <SeekBar>
                  <BufferBar />
                  <PlayBar />
                </SeekBar>
              </div>
              <CurrentTime />
              <Duration />
            </div>

            <div className="jp-controls jp-icon-controls">
              <button onClick={() => this.previousEpisode()} className="back mdi mdi-skip-backward"><span className="acc-btn">Previous Episode</span></button>
              <button onClick={() => this.jumpBackward()} className="back mdi mdi-rewind"><span className="acc-btn">Back</span></button>
              <Play><i className={`fa mdi ${player.paused ? "mdi-play" : "mdi-pause"}`}></i><span className="acc-btn">Play</span></Play>
              <button onClick={() => this.jumpForward()} className="forward mdi mdi-fast-forward" ><span className="acc-btn">Forward</span></button>
              <button onClick={() => this.nextEpisode()} className="forward mdi mdi-skip-forward" ><span className="acc-btn">Next episode</span></button>
            </div>

            <div className="jp-volume">
              <Mute><i className={`mdi ${player.muted ? "mdi-volume-off" : "mdi-volume-high"}`}></i><span className="acc-btn">Mute</span></Mute>
                {this.renderVolumeBar()}
            </div>

            <div className="jp-actions">
              <button className="mdi mdi-share" onClick={() => this.setState({ shareModalOpen: true })}><span className="acc-btn">Share</span></button>
              {this.renderBibleGatewayLink()}
              <a 
                download
                href={`https://player.dailyaudiobible.com/file.php?file=${player.src}`}
              >
                <i className="fa fa-download mdi mdi-download" />
              </a>
            </div>

          <BrowserUnsupported />
          </Gui>
        </JPlayer>
      </div>
    )
  }
}


const mapStateToProps = state => {
  return { player: state.jPlayers.DailyAudioBiblePlayer }
}


export default withRouter(
  withApollo(
    connect(mapStateToProps)(Player)
  )
)