import React, { Component } from 'react'
import Channel from './Channel'

class ChannelList extends Component {

  componentDidMount() {
    const { setBackButton, match, hidePlayer } = this.props

    setBackButton(match.url)
    hidePlayer()

  }

  render() {

    const { setChannel, channels } = this.props

    return (
      <div className="channels">
        <div className="container">
          <h1>Channels</h1>
          <div className="channels__list flex-row flex-row--25">
            <Channel channels={channels} setChannel={setChannel} />
          </div>
        </div>
      </div>
    )
  }
}

export default ChannelList
