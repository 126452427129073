import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import EpisodeList from './EpisodeList'

// THIS IS A RECEIVER FOR THE EPISODE
// THE PLAYER BREAKS WITHOUT AN AUDIO FILE,
// SO I USED THIS TO PREVENT IT FROM BREAKING
// WHILE THE DATA LOADS
class Receiver extends Component {
  constructor(props) {
    super(props)

    this.onResize = this.onResize.bind(this)
    this.state = { 
      windowWidth: window.innerWidth,
      content: ''
    }
  }

  onResize() {
    this.setState({ windowWidth: window.innerWidth })
  }

  componentDidMount() {
    this.props.subscribeToMore()

    const { match, showPlayer, setUrl, setBackButton, selectedChannel, setPlayingChannel, channels, playingChannel, 
            episodes, playingEpisode, setChannel, setPlayingEpisodeOnReload } = this.props
    let { channel, date } = match.params

    if (!playingChannel) {
      for (const channel of channels) {
        if (match.params.channel === channel.key) {
          setPlayingChannel(channel)
        }
      }
    }

    if (!selectedChannel) {
      setChannel(playingChannel)
    }

    if (!playingEpisode){
      setPlayingEpisodeOnReload(match.params.date, episodes)
    }

    // this function calls the episode finder when called
    // with a date as the second argument
    setBackButton(channel)

    showPlayer()
    setUrl(`/${channel}/${date}`)
    // Listens for a resize, and calls the onResize function
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    // stop the event listener
    window.removeEventListener('resize', this.onResize)
  }

  componentDidUpdate() {
    const { match, url, setUrl } = this.props
    let { channel, date } = match.params

    if(`/${channel}/${date}` !== url) {
      setUrl(`/${channel}/${date}`)
    }
  }


  renderEpisodeList() {
    if (this.state.windowWidth > 900) {
      const { selectedChannel, episodes, setPlayerEpisode } = this.props
      
      return <EpisodeList 
        selectedChannel={selectedChannel} 
        episodes={episodes} 
        setPlayerEpisode={setPlayerEpisode}
      />
    }
  }

  render() {

    return (
      // this view is specifically to make a given
      // episode indexable with the channel and date
      <div className="receiver">
        {this.renderEpisodeList()}
        {/* Nothing is rendered.
            Instead, the player
            becomes     visable. */}
      </div>
    )
  }
}


export default withRouter(Receiver)