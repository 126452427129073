import React, { Component } from 'react'

const giveParagraph = "Your giving and partnership is what keeps Daily Audio Bible going. By your support, we are able to reach the nations of the earth with the Bible. Give today and become part of this amazing story!"

let env = ''
let url = window.location.hostname
switch (url) {
  case 'localhost':
    env = 'development'
    break
  case 'lite.dailyaudiobible.test':
    env = 'development'
    break
  case 'lite.staging.dailyaudiobible.com':
    env = 'staging'
    break
  default:
    env = 'production'
    break
}

class Give extends Component {
  constructor(props) {
    super(props)

    this.state = { monthlyGift: true, giftAmount: 25, env: '', giveLink: '' }
  }

  componentDidMount() {
    const { setBackButton, hidePlayer } = this.props

    switch (env) {
      case ('development'):
        this.setState({ giveLink: 'http://give.dailyaudiobible.test' })
        break
      case ('staging'):
        this.setState({ giveLink: 'https://give.staging.dailyaudiobible.com' })
        break
      default:
        this.setState({ giveLink: 'https://give.dailyaudiobible.com' })
        break
    }

    hidePlayer()
    setBackButton("toList")
  }

  filterNumbers(event) {
    if (event.target.value % 1 === 0) {
      this.setState({ giftAmount: event.target.value })
    }
  }

  render() {
    const { monthlyGift, giftAmount, giveLink } = this.state

    return (
      <div className="give">
        <div className="container">
          <h1>Give</h1>
          <h3>Help us reach the nations!</h3>
          <p>{giveParagraph}</p>
          <div className="toggle">
            <button 
              onClick={() => this.setState({ monthlyGift: true })}
              className={monthlyGift ? "black" : "white"}
            >
              Monthly
            </button>
            <button 
              onClick={() => this.setState({ monthlyGift: false })}
              className={monthlyGift ? "white" : "black"}
            >
              One Time
            </button>
          </div>
          <div className="give__form">
            <input
              className="give__amount"
              type="number"
              min="1"
              value={giftAmount}
              onChange={event => this.filterNumbers(event)}
              step="1"
              />
            {/* This is a link to the give API */}
            {/* it passes a campaign ID (hard coded as 1 (daily audio bible)), */}
            {/* a donation interval (monthly/single), and amount as routes */}
            <a
              target="_blank" 
              rel="noopener noreferrer"
              href={`${giveLink}/1/${monthlyGift ? 'monthly' : 'single'}/${giftAmount}`} 
              className="form-button primary-button"
              style={{ textAlign: 'center' }}
            >
              Give
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Give
