import React, { Component } from 'react'
import { BrowserRouter, Route, HashRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions } from 'react-jplayer'
import moment from 'moment-timezone'
import ReactGA from 'react-ga'
import AppContainer from './AppContainer'
import ChannelList from './ChannelList'
import EpisodeRoute from './EpisodeRoute'
import Receiver from './Receiver'
import Header from './Header'
import Initiatives from './Initiatives'
import Navigation from './Navigation'
import PlayerView from './PlayerView'
import MiniPlayer from './MiniPlayer'
import Give from './Give'
import Spinner from './Spinner'
import { Query } from 'react-apollo'
import ErrorMessage from './ErrorMessage'
import List from './List'
import { GET_CHANNELS } from '../queries'
import WindowAPI from './WindowAPI'


class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      // Config state
      showMenu: false,
      showPlayer: false,
      url: '',
      backButton: '',

      // Content state
      playingChannel: null,
      selectedChannel: null,
      selectedEpisode: null,
      playingEpisode: null
    }
  }

  componentDidMount() {
      //Initial page load
      ReactGA.initialize(process.env.REACT_APP_WEB_GOOGLE_ANALYTICS_ID)
      ReactGA.pageview(window.location.pathname)
  }

  setDefaultChannel(channels) {
    const { selectedChannel } = this.state
    if (!selectedChannel) {
      for (const channel of channels) {
        if ('dab' === channel.key) {
          this.setState({ selectedChannel: channel })
        }
      }
    }
  }

  
  setPlayingEpisodeOnReload(episodeDate, episodes) {

    let community = false
    let other = false

    // If "community" or "other" was in the url date
    if (episodeDate.includes("community")) {
      episodeDate = episodeDate.replace("community", "")
      community = true
    } else if (episodeDate.includes("other")) {
      episodeDate = episodeDate.replace("other", "")
      other = true
    }
    
    // Get the date pieces of the url
    const year = episodeDate.slice(4,8)
    const month = episodeDate.slice(0,2)
    const day = episodeDate.slice(2,4)

    // Get a date ISO string to compare with our episode list
    const newEpisodeDate = new Date(`${year}-${month}-${day}`).toISOString()

    // for loop so we can break when we find the right episode
    for (var i = 0; i < episodes.length; i++) {
      
      const episode = episodes[i]

      // If we are on the right date
      if (newEpisodeDate === episode.date) {

        if (
          // If it's not a reading, the url had "community", and the title includes "community"
          (
            community &&
            episode.type === "other" &&
            episode.title.toLowerCase().includes('community')

          // If the url had "other" and it's not a reading
          ) || (
            other &&
            episode.type === "other"

          // If the url did not have "other" or "community" and the episode is a reading
          ) || (
            !other &&
            !community &&
            episode.type === "reading"
          )
        ) {

          // Use that episode
          this.setState({ playingEpisode: episode })
          this.setPlayerEpisode(episode)
          break
        }
      }
    }
  }


  setPlayerEpisode(episode, setPlayingChannel = true) {
    const newMedia = {
        title: episode.title,
        sources: {
          m4a: episode.audioURL
        }
    }
    this.setState({ 
      playingEpisode: episode,
      selectedEpisode: episode
    })
    // Set the episode in the jplayer
    this.props.dispatch(actions.setMedia("DailyAudioBiblePlayer", newMedia))
    const is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    // Set the episode to play
    if (!is_safari) {
        this.props.dispatch(actions.play("DailyAudioBiblePlayer"))
    }
    if (setPlayingChannel && this.state.selectedChannel) {
        this.setState({ playingChannel: this.state.selectedChannel })
    }
  }

  updatePlayingEpisode(episode) {

    if (episode.audioURL !== this.props.player.media.sources.m4a) {
      return
    }


    // Set if paused or playing 
    const status = this.props.player.paused
    // Set location in the playback
    const currentTimePercent = this.props.player.currentPercentAbsolute

    this.setState({ playingEpisode: episode })

    const newMedia = {
      title: episode.title,
      sources: {
        m4a: episode.audioURL
      }
    }
    // Update the episode in the jplayer
    this.props.dispatch(actions.setMedia("DailyAudioBiblePlayer", newMedia))
    // Set the time based on the previous location in playback
    if (currentTimePercent === 0) {
      this.props.dispatch(actions.setPlayHead("DailyAudioBiblePlayer", currentTimePercent))
    }
    // If not previously paused, play track
    if (!status) {
      this.props.dispatch(actions.play("DailyAudioBiblePlayer"))
      return
    } 
  }

  episodeQueryDate(channel) {

    const { bufferLength, bufferPeriod, rolloverDay, rolloverMonth } = channel

      // Set toDate
      const toDate = moment().format('YYYY-MM-DD')

      const year = moment().year()

      // Set fromDate
      let fromDate = moment(`${year}/${rolloverMonth}/${rolloverDay}`)

      // Adjust fromDate for rollover period

      // Set a date for when the buffer cutoff begins
      const bufferCutoff = moment(`${year}/${rolloverMonth}/${rolloverDay}`).add(bufferPeriod, 'days')
      
      // Set a date for when the buffer starts
      const bufferStart = moment(`${year}/${rolloverMonth}/${rolloverDay}`).subtract(bufferLength, 'days')

      // Get a current date
      const currentDate = moment()

      // Get the difference in days between today and the buffer cutoff
      const dateDiff = Math.floor(moment.duration(currentDate.diff(bufferCutoff)).asDays())

      // If the current date is on or after the rollover date
      // but before the rollover date plus the buffer period
      if ( 
        toDate >= fromDate.format('YYYY-MM-DD') &&
        toDate < bufferCutoff.format('YYYY-MM-DD')
      ) {

        // Use the buffer start date as the low date
        fromDate = bufferStart.format('YYYY-MM-DD')

      // Once we pass the buffer cutoff, begin adding a day to the
      // date low string each day until the date low string is the Rollover Date
      } else if ( dateDiff < parseInt(bufferLength, 10) ) {

        bufferStart.add(dateDiff, 'days')
        fromDate = bufferStart.format('YYYY-MM-DD')

      } else {

        // Otherwise, use the rollover date as the date low string.
        fromDate = fromDate.format('YYYY-MM-DD')
      }

      return `${fromDate}T00:00:00.000Z`
  }

  render() {
    const { showMenu, showPlayer, url, backButton, selectedChannel, selectedEpisode, playingChannel, playingEpisode } = this.state

    return (
      <Router>

        <AppContainer>
          <div className={`app ${showMenu ? "navigation-on" : ""} ${showPlayer ? "no-scrollbar" : ''}`}>

            <Query query={GET_CHANNELS}>

              {({ data, error, subscribeToMore }) => {
                if (error) {
                  return <ErrorMessage error={error} />
                }
                
                if (!data) {
                  return <Spinner />
                }

                const { channels } = data

                const compare = (a,b) => {
                  a.id = parseInt(a.id, 10)
                  b.id = parseInt(b.id, 10)
                  if (a.id * 10 < b.id * 10) return -1
                  if (a.id * 10 > b.id * 10) return 1
                  return 0
                }
                channels.sort(compare)

              const listProps = {
                playingChannel,
                selectedEpisode,
                playingEpisode,
                selectedChannel,
                setPlayerEpisode: this.setPlayerEpisode.bind(this),
                updatePlayingEpisode: this.updatePlayingEpisode.bind(this),
                
                setEpisode: (newSelectedEpisode) => {this.setState({ selectedEpisode: newSelectedEpisode })},
                setChannel: (newSelectedChannel) => { this.setState({ selectedChannel: newSelectedChannel })},
                setPlayingEpisode: (newPlayingEpisode) => {this.setState({ playingEpisode: newPlayingEpisode })},
                setPlayingChannel: (newPlayingChannel) => {this.setState({ playingChannel: newPlayingChannel })}
              }

                return (
                  <WindowAPI
                    channel={playingChannel}
                    episode={playingEpisode}
                    queryDate={this.episodeQueryDate(selectedChannel ? selectedChannel : channels[0])}
                    setPlayerEpisode={this.setPlayerEpisode.bind(this)}
                  >
                    <List
                      queryDate={this.episodeQueryDate(selectedChannel ? selectedChannel : channels[0])}
                      queryCursor={null}
                      queryEpisodes={[]}
                      component={Navigation}
                      componentName="Navigation"
                      onClick={() => this.setState({ showMenu: false })} 
                      {...listProps}
                      subscribeToMoreChannels={subscribeToMore}
                    />
                    <div className={`app__body ${showMenu ? "no-scroll" : ""}`}>
                      <Header 
                        onClick={() => this.setState({ showMenu: !showMenu })} 
                        backButton={backButton} 
                      />
                      <div className="drawer-helper" onClick={() => this.setState({ showMenu: false })}></div>
                      <div className="app__content">

                        {/* Routes take a given channel and date */}
                        <Route 
                          exact 
                          path="/" 
                          render={props => {
                            this.setDefaultChannel(channels)
                            return (
                              <div>
                                  <ChannelList 
                                    hidePlayer={() => this.setState({ showPlayer: false })} 
                                    setBackButton={backButton => this.setState({ backButton })}
                                    setChannel={selectedChannel => this.setState({ selectedChannel })}
                                    channels={channels}
                                    {...props}
                                  />
                              </div>
                            )
                          }}
                        />

                        <Route 
                          exact 
                          path="/initiatives" 
                          render={props => {
                            this.setDefaultChannel(channels)
                            return (
                              <Initiatives 
                                hidePlayer={() => this.setState({ showPlayer: false })} 
                                setBackButton={backButton => this.setState({ backButton })} 
                                {...props} 
                              />
                            )
                          }} 
                        />

                        {/* This page will need to prepopulate the fields in the already linked page */}
                        <Route 
                          exact 
                          path="/give" 
                          render={props => {
                            this.setDefaultChannel(channels)
                            return (
                              <Give 
                                hidePlayer={() => this.setState({ showPlayer: false })} 
                                setBackButton={backButton => this.setState({ backButton })} 
                                {...props} 
                              />
                            )
                          }} 
                        /> 

                        <Route 
                            exact 
                            path="/:channel" 
                            render={props => {
                              if (!selectedChannel) {
                                for (const channel of channels) {
                                  if (props.match.params.channel === channel.key) {
                                    this.setState({ selectedChannel: channel })
                                  }
                                }
                              return <Spinner />
                            }

                            return (
                              <List
                                queryDate={this.episodeQueryDate(selectedChannel)}
                                queryCursor={null}
                                queryEpisodes={[]}
                                component={EpisodeRoute}
                                componentName="EpisodeRoute"
                                setUrl={url => this.setState({ url })}
                                hidePlayer={() => this.setState({ showPlayer: false })} 
                                showPlayer={() => this.setState({ showPlayer: true })} 
                                setBackButton={backButton => this.setState({ backButton })} 
                                channels={channels}
                                {...props} 
                                {...listProps}
                              />
                            )
                          }}
                        />

                        <Route 
                          exact 
                          path="/:channel/:date" 
                          render={props => {
                            if (!playingChannel) {
                              for (const channel of channels) {
                                if (props.match.params.channel === channel.key) {
                                  this.setState({ playingChannel: channel })
                                }
                              }
                            return <Spinner />
                          }

                          return (
                              <List 
                                queryDate={this.episodeQueryDate(playingChannel)}
                                queryCursor={null}
                                queryEpisodes={[]}
                                component={Receiver}
                                componentName="Receiver"
                                setBackButton={backButton => this.setState({ backButton })} 
                                url={url}
                                setUrl={url => this.setState({ url })} 
                                showPlayer={() => this.setState({ showPlayer: true })}                 
                                channels={channels}
                                setPlayingEpisodeOnReload={this.setPlayingEpisodeOnReload.bind(this)}
                                {...props}
                                {...listProps}
                              />
                            )
                          }}
                        />

                        {/* PlayerView and MiniPlayer exist independent of routes */}
                        <PlayerView
                          showPlayer={showPlayer} 
                          url={url} 
                          selectedEpisode={selectedEpisode}
                          playingChannel={playingChannel}
                          queryDate={this.episodeQueryDate(playingChannel ? playingChannel : channels[0])}
                          setPlayerEpisode={this.setPlayerEpisode.bind(this)}
                        />
                        <MiniPlayer
                          hidden={showPlayer ? true : false}
                          selectedEpisode={selectedEpisode}
                          playingChannel={playingChannel}
                          playingEpisode={playingEpisode}
                        /> 

                      </div> {/* app-content */}
                      
                      <div className="app__bg"></div>
                    </div>
                  </WindowAPI>
                )          
              }}
            </Query>
          </div>
        </AppContainer>
      </Router>
    )
  }
}

const Router = (props) => {
  if (window.location.hostname) {
    return  <BrowserRouter>{props.children}</BrowserRouter>
  } else {
    return  <HashRouter>{props.children}</HashRouter>
  }

}
const mapStateToProps = state => {
  return { player: state.jPlayers.DailyAudioBiblePlayer }
}

// connect() makes dispatch available for Receiver
export default connect(mapStateToProps)(App)
