import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import Spinner from './Spinner'
import { fetchInitiatives } from '../actions'

class Initiatives extends Component {
  componentDidMount() {
    const { fetchInitiatives, setBackButton, hidePlayer } = this.props

    hidePlayer()
    setBackButton("toList")
    fetchInitiatives()
  }

  renderInitiatives() {
    return _.map(this.props.initiatives.links, (link, i) => {
      return (
        <div key={i}>
          <a target="_blank" href={link.link} rel="noopener noreferrer">
            <img src={link.urlPhone} alt={link.title} className="image" />
          </a>
        </div>
      )
    })
  }

  render() {
    if (!this.props.initiatives.title) {
      return <Spinner />
    }

    return (
      <div className="initiatives">
        <div className="container">
          <h1>Initiatives</h1>
          {this.renderInitiatives()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { initiatives: state.initiatives }
}

export default connect(mapStateToProps, { fetchInitiatives })(Initiatives)
