import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = ({ retracted: false })
  }

  componentDidMount() {
    if (window.innerWidth < 900) {
      this.setState({ retracted: true })
    }

    window.setTimeout(() => {
      window.addEventListener('resize', () => {
        if (window.innerWidth < 900) {
          this.setState({ retracted: true })
        } else {
          this.setState({ retracted: false })
        }
      })
    }, 1000)
  }

  renderBackButton() {
    const { backButton } = this.props
    if (this.state.retracted) {
      let backLink = '/'

      if (backButton === '/' || backButton === 'toList') {
        backLink = '/'
      } else {
        backLink = `/${backButton}`
      }
  
      if (backButton === '/') {
        return
      }
  
      return (
        <Link className="mdi mdi-chevron-left" to={backLink}><span className="acc-btn">Back</span></Link>
      )  
    }

    if (backButton === "/") {
      return
    }
  }

  render() {
    return (
      <header className="app__header">
        <div className="header__nav">
          <div className="header__menu">
            <button className="mdi mdi-menu" onClick={this.props.onClick}><span className="acc-btn">Menu</span></button>
          </div>
          <div className="header__back">
            {this.renderBackButton()}
          </div>
        </div>
        <div className="header__logo">
          <img alt="daily audio bible logo" src={require("../images/logo.svg")}></img>
        </div>
      </header>
    )
  }
}

export default Header
