import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import EpisodeList from './EpisodeList'
import Spinner from './Spinner'
import _ from 'lodash'
import moment from 'moment-timezone'

class EpisodeRoute extends Component {
  constructor(props) {
    super(props)

    this.onResize = this.onResize.bind(this)
    this.state = { 
      windowWidth: window.innerWidth, 
      throttle: _.throttle(this.onResize, 500, { 'trailing': false })
    }
  }

  onResize() {
    const { hidePlayer, showPlayer, episodes, playingChannel, match, channels, 
            setPlayingChannel, setPlayingEpisode, playingEpisode, setPlayerEpisode } = this.props

    this.setState({ windowWidth: window.innerWidth })

    if (this.state.windowWidth < 900) {
      hidePlayer()
    } else {
      if (!playingChannel) {
        for (const channel of channels) {
          if (match.params.channel === channel.key) {
            setPlayingChannel(channel)
          }
        }
      }
      if (!playingEpisode) {
        setPlayingEpisode(episodes[0])
        setPlayerEpisode(episodes[0])
      }
      showPlayer()
      
    }
  }

  componentDidMount() {
    this.props.subscribeToMore()

    // this.props.match.params pulls from the route
    const { match, setBackButton, showPlayer, hidePlayer, player, setUrl, episodes, playingChannel, channels, 
            setPlayingChannel, setPlayingEpisode, setPlayerEpisode, playingEpisode } = this.props
    const { channel } = match.params

    const defaultDate = (episode) => {
      const formatedDate = moment(episode.date).tz('utc').format('MMDDYYYY')

      if (episode.type === 'reading') {
        return formatedDate    
      } else {
        const prefix = episode.title.toLowerCase().includes('community') ? 'community' : 'other'
        return `${prefix}${formatedDate}`
      }
    }

    if (channel === "initiatives" || channel === "give") {
      return
    }
      
    if (window.innerWidth < 900) {
      hidePlayer()
    } else {
      if (!playingChannel) {
        for (const channel of channels) {
          if (match.params.channel === channel.key) {
            setPlayingChannel(channel)
          }
        }
      }
      if (!playingEpisode) {
        setPlayingEpisode(episodes[0])
        setPlayerEpisode(episodes[0])
      }
      showPlayer()

      if (player.src === null) {
        setUrl(`/${channel}/${defaultDate(episodes[0])}`)
      }
    }
    window.addEventListener('resize', this.state.throttle) 
    setBackButton("toList")
  }

  componentWillUnmount() {
    // stop the timer and event listener
    window.removeEventListener('resize', this.state.throttle)
  }
  
  componentDidUpdate() {

    const { match } = this.props
    const { channel } = match.params

    if (channel === "initiatives" || channel === "give") {
      return
    }
  }

  render() {
    const { episodes, match, selectedChannel, playingChannel, selectedEpisode, setPlayerEpisode } = this.props

    if (match.params.channel === "initiatives" || match.params.channel === "give") {
      return <div />
    }

    if (
      !selectedChannel ||
      (
        window.innerWidth > 900 &&
        (!playingChannel || !selectedEpisode)
      )
    ) {
      return <Spinner />
    }

    return (
      <EpisodeList 
        selectedChannel={selectedChannel} 
        episodes={episodes} 
        setPlayerEpisode={setPlayerEpisode}  
      />
    )
  }
}

const mapStateToProps = ({ jPlayers }) => {
  return { player: jPlayers.DailyAudioBiblePlayer }
}

export default connect(mapStateToProps)(withRouter(EpisodeRoute))