import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import reduxThunk from 'redux-thunk'
import '@mdi/font/css/materialdesignicons.min.css'
import './index.css'
import 'react-jplayer/src/less/skins/sleek.less'
import 'react-jplayer/src/less/controls/iconControls.less'
import App from './components/App'
import registerServiceWorker from './registerServiceWorker'
import reducers from './reducers'
import 'babel-polyfill'

// Apollo
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
// Subscriptions
import { split } from 'apollo-link'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'

//Force HTTPS
import HttpsRedirect from 'react-https-redirect';

const store = createStore(reducers, {}, applyMiddleware(reduxThunk))

let env = ''
let url = window.location.hostname
switch (url) {
  // To test Subscriptions on desktop app
  // case '':
  //   env = 'development'
  //   break
  case 'localhost':
    env = 'development'
    break
  case '10.0.1.33':
    env = 'development'
    break
  case 'lite.dailyaudiobible.test':
    env = 'development'
    break
  case 'lite.staging.dailyaudiobible.com':
    env = 'staging'
    break
  default:
    env = 'production'
    break
}

let GRAPHQL_BASE_URL = ''
let WS_URL = ''
switch (env) {
 case 'development':
    GRAPHQL_BASE_URL = 'http://service.dailyaudiobible.test:8000/graphql'
    WS_URL = 'ws://service.dailyaudiobible.test:8000/graphql'
   break
 case 'staging':
   GRAPHQL_BASE_URL = 'https://service.staging.dailyaudiobible.com/graphql'
   WS_URL = 'wss://service.staging.dailyaudiobible.com/graphql'
   break
 case 'production':
    GRAPHQL_BASE_URL = 'https://service.dailyaudiobible.com/graphql'
    WS_URL = 'wss://service.dailyaudiobible.com/graphql'
   break
 default:
   GRAPHQL_BASE_URL = 'https://service.dailyaudiobible.com/graphql'
   WS_URL = 'wss://service.dailyaudiobible.com/graphql'
   break
}

const httpLink = new HttpLink({
  uri: GRAPHQL_BASE_URL
})

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: WS_URL,
  options: {
    reconnect: true
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const splitLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }
  if (networkError) {
    console.log(`[Nework Error]: `)
    console.log(networkError)
  }
})

const link = ApolloLink.from([errorLink, splitLink])

const cache = new InMemoryCache()

const client = new ApolloClient({
  link,
  cache
})


ReactDOM.render(
  <HttpsRedirect disabled={env == 'development'}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  </HttpsRedirect>,
  document.getElementById('root')
)

registerServiceWorker()
