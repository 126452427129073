import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'

// Build date URL
const dateToUrl = (type, title, epDate) => {
    const formatedDate = moment(epDate).tz('utc').format('MMDDYYYY')

    if (type === 'reading') {
        return formatedDate     
    } else {
        const prefix = title.toLowerCase().includes('community') ? 'community' : 'other'
        return `${prefix}${formatedDate}`
    }
}

const Episode = ({ episodes, selectedChannel, setPlayerEpisode }) => {
    return episodes.map((episode) => {
        const { type, id, title, date, description, episodeId } = episode
        return (
            <div 
                key={id} 
                onClick={() => setPlayerEpisode(episode)}
            >
                <Link id={episodeId} to={`/${selectedChannel.key}/${dateToUrl(type, title, date)}`}>
                    <h6>{title}</h6>
                    <p>{description}</p>
                </Link>
            </div>
        )    
    })
}

export default Episode