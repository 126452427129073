import axios from 'axios'
import { FETCH_INITIATIVES } from './types'

let env = ''
let url = window.location.hostname
switch (url) {
  case 'localhost':
    env = 'development'
    break
  case 'lite.dailyaudiobible.test':
    env = 'development'
    break
  case 'lite.staging.dailyaudiobible.com':
    env = 'staging'
    break
  default:
    env = 'production'
    break
}

let feedLink = ''
switch (env) {
  case 'development':
    feedLink = 'https://feed.dailyaudiobible.com'
    break
  case 'staging':
    feedLink = 'https://dabfeed.staging.wpengine.com'
    break
  case 'production':
    feedLink = 'https://feed.dailyaudiobible.com'
    break
  default:
    feedLink = 'https://feed.dailyaudiobible.com'
    break
}

// FETCHES AN ARRAY OF INITIATIVES
export const fetchInitiatives = () => async dispatch => {
  const initiatives = await axios.get(`${feedLink}/wp-json/lutd/v1/content`)

  let foundView = null

  initiatives.data.views.forEach(view => {
    if (view.title === "Initiatives") {
      foundView = view      
    }
  })

  return dispatch({ type: FETCH_INITIATIVES, payload: foundView })
}