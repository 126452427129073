import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ReactGA from 'react-ga'

// This is a wrapper container for the whole app
// It is primarily to track pageviews for google analytics
class AppContainer extends Component {

  componentWillMount() {
    // Page switch
      this.unlisten = this.props.history.listen((location, action) => {
        ReactGA.initialize(process.env.REACT_APP_WEB_GOOGLE_ANALYTICS_ID)
        ReactGA.pageview(location.pathname)

        // Google Analytics event listener for Desktop App
        window.dispatchEvent( new CustomEvent('lutd-pageview', {
          detail: {
            page: location.pathname
          }
        }) )
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  render() {
    return (
      <div>{this.props.children}</div>
    )
  }
}

export default withRouter(AppContainer)
