import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import moment from 'moment-timezone'
import { CHANNEL_SUBSCRIPTION } from '../queries'
import packageJson from '../../package.json'

class Navigation extends Component {
  componentDidMount() {
    const { selectedChannel, playingChannel, setChannel, setPlayingChannel, subscribeToMoreChannels, refetch } = this.props

    // Subscribe to the Episodes
    this.props.subscribeToMore()

    // Subscription for Channels
    const updateChannels = subscribeToMoreChannels => {
      subscribeToMoreChannels({
        document: CHANNEL_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev
            // Define the new or updated Channel from the subscriptionData
            const newFeedItem = subscriptionData.data.channelPublished.channel
            let allChannels
            // If channels are old ones being updated, update immediately
            if (selectedChannel && selectedChannel.channelId === newFeedItem.channelId) {
              setChannel(newFeedItem)
            }
            if (playingChannel && playingChannel.channelId === newFeedItem.channelId) {
              setPlayingChannel(newFeedItem)
            }
            // If the channels are new, it will be added immediately to the channel list
            if (!prev.channels.find((chnl) => chnl.channelId === newFeedItem.channelId)) {
                allChannels = Object.assign({}, prev, {
                  channels: [...prev.channels, newFeedItem]
                })
                return allChannels
            } else {
                return prev
            }
        }
    })
  }

    updateChannels(subscribeToMoreChannels)
    console.log("Subscribe")

    let now = new Date().getTime()
    let nextMidnight = new Date().setHours(24,0,0,0)

    // Start a countdown to check if we can update the view (+1 second just in case)
    const interval = nextMidnight - now + 1000

    console.log(`Checking for a new episode in ${interval / 1000 / 60 / 60} hours`)

    // Run at interval
    setInterval(() => {
        const now = new Date().getTime()

        // If now is on or after midnight and
        // the last time we polled was before midnight
        if (
            now >= nextMidnight &&
            now - interval < nextMidnight
        ) {
            console.log('TIME FOR A NEW EPISODE')

            // Get the next episode
            refetch()

            // Reset next midnight, just in case the user is on for over 24 hours
            nextMidnight = new Date().setHours(24,0,0,0)
        }
    }, interval)
  }

  // Build URL for player link in the menu
  buildPlayerUrl() {
    const { selectedChannel, selectedEpisode } = this.props

    let formatedDate = moment(selectedEpisode.date).tz('utc').format('MMDDYYYY')

    if (selectedEpisode.type === 'reading') {
      return `/${selectedChannel.key}/${formatedDate}`    
    } else {
      const prefix = selectedEpisode.title.toLowerCase().includes('community') ? 'community' : 'other'
      return `/${selectedChannel.key}/${prefix}${formatedDate}`
    }
  }

  onPlayerClick() {
    const { selectedChannel, playingChannel, setChannel, onClick, episodes, setPlayerEpisode, setPlayingEpisode } = this.props

    if(playingChannel) {
      if(selectedChannel !== playingChannel) {
        setChannel(playingChannel)
      }
    }
    if(!playingChannel) {
      setPlayingEpisode(episodes[0])
      setPlayerEpisode(episodes[0]) 
    }
    
    onClick()
  }

  playerLink(episodes, selectedChannel) {
      if (!episodes || !selectedChannel) {
          return <a className="mdi mdi-play-circle" href="updatedByEpisodeSelection">Player</a>
      }
      return <Link className="mdi mdi-play-circle" onClick={() => this.onPlayerClick()} to={this.buildPlayerUrl()}>Player</Link>
  }

  render() {
    // onClick gets rid of the nav menu
    const { onClick, episodes, selectedChannel } = this.props

    // Create issue link with relevant query strings
    let issueLink
    
    if (window.location.hostname) {
      let osName
      if (navigator.appVersion.indexOf("Win")!==-1) osName="windows"
      if (navigator.appVersion.indexOf("Mac")!==-1) osName="mac"
      if (navigator.appVersion.indexOf("Linux")!==-1) osName="linux"
      issueLink = `https://dailyaudiobible.com/report-${process.env.REACT_APP_FEEDBACK}?os=${osName}&version=${packageJson.version}`
    } else {
      issueLink = `https://dailyaudiobible.com/report-${process.env.REACT_APP_FEEDBACK}?os=${process.env.REACT_APP_OS}&version=${process.env.REACT_APP_VERSION}`
    }

    return (
      <div className="navigation" onClick={onClick}>
        {this.playerLink(episodes, selectedChannel)}
        <Link className="mdi mdi-microphone" onClick={onClick} to="/">Channels</Link>
        <Link className="mdi mdi-call-made" onClick={onClick} to="/initiatives">Initiatives</Link>
        <Link className="mdi mdi-heart" onClick={onClick} to="/give">Give</Link>
        <a className="mdi mdi-comment outbound-link" target="_blank" rel="noopener noreferrer" onClick={onClick} href={issueLink}>Feedback</a>
      </div>
    )
  }
}

export default withRouter(Navigation)
