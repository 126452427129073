import React from 'react' 
import { GET_EPISODES, EPISODE_SUBSCRIPTION } from '../queries'
import Spinner from './Spinner'
import { Query } from 'react-apollo'
import ErrorMessage from './ErrorMessage'
import moment from 'moment-timezone'


const List = (props) => {
    const { queryDate, queryEpisodes, queryCursor, playingChannel, selectedChannel, selectedEpisode, componentName, 
        setEpisode, subscribeToMoreChannels, playingEpisode, updatePlayingEpisode } = props
        
    const theChannelId = () => {
        if (componentName === "EpisodeRoute") {
            return selectedChannel.channelId
        } else if (componentName === "Receiver") {
            return playingChannel.channelId
        } else if (componentName === "Navigation") {
            return playingChannel ? playingChannel.channelId : 227
        }
    }

    const filterEpisodes = (episodeList) => {
        let formatedDate
        let filteredEpisodes = []
        let todaysDate = moment().format('YYYYMMDD')
        // For testing
        // todaysDate = 20191020

        // Filter Episodes so that only the Episodes from the day of and older will show up
        episodeList.forEach((episode) => {
            formatedDate = moment(episode.date).tz('utc').format('YYYYMMDD')
            formatedDate = parseInt(formatedDate, 10)

            if (todaysDate >= formatedDate) {
                filteredEpisodes.push(episode)
            }
            return filteredEpisodes
        })      
        return filteredEpisodes
    }
    
    return (
        <Query
            query={GET_EPISODES}
            variables={{
                episodeDate: queryDate,
                cursor: queryCursor,
                channelId: theChannelId()
            }}
            >
            {({ error, data, subscribeToMore, refetch }) => {
                if (error) {
                return <ErrorMessage error={error} />
                }
                if (!data) {
                return <Spinner />
                }

                let episodeList = [...queryEpisodes, ...data.episodes.edges]

                episodeList = filterEpisodes(episodeList)    

                if (!selectedEpisode) {
                    setEpisode(episodeList[0])
                    return <Spinner />
                }

                if (data.episodes.pageInfo.hasNextPage) {

                    return <List
                        {...props}
                        queryDate={queryDate}
                        queryCursor={data.episodes.pageInfo.endCursor}
                        queryEpisodes={episodeList}
                        subscribeToMoreChannels={subscribeToMoreChannels}
                    />

                } else {

                    // Subscription for Episodes
                    const updateEpisodes = () => subscribeToMore({
                        document: EPISODE_SUBSCRIPTION,
                        updateQuery: (prev, { subscriptionData }) => {
                            if (!subscriptionData.data) return prev
                            // Define the new or updated episode from the subscriptionData
                            const newFeedItem = subscriptionData.data.episodePublished.episode
                            // If the episodes are new and you are on the episode's channel, new episode will be added immediately to the episode list
                            if (selectedChannel && newFeedItem.channelId === selectedChannel.channelId && !prev.episodes.edges.find((eps) => eps.episodeId === newFeedItem.episodeId)) {
                                let allEpisodes
                                allEpisodes = Object.assign({}, prev, {
                                    episodes: {
                                        edges: [ newFeedItem, ...prev.episodes.edges ],
                                        pageInfo: prev.episodes.pageInfo,
                                        __typename: prev.episodes.__typename
                                    } 
                                })
                                return allEpisodes
                            // If the episodes are old ones being updated, update immediately. 
                            // If the updated episode is currently playing, update episode in the player
                            } else {
                                if(playingEpisode && playingEpisode.episodeId === newFeedItem.episodeId) {
                                    updatePlayingEpisode(newFeedItem)
                                }
                                if(selectedEpisode.episodeId === newFeedItem.episodeId) {
                                    updatePlayingEpisode(newFeedItem)
                                    setEpisode(newFeedItem)
                                }
                                return prev
                            }
                        }
                    })

                    return (
                        <props.component
                            {...props}
                            refetch={refetch}
                            episodes={episodeList}
                            subscribeToMore={updateEpisodes}
                            subscribeToMoreChannels={subscribeToMoreChannels}
                        />
                    )
                }
                
            }}
        </Query>
    )
}

export default List