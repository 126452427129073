import React, { Component } from 'react'
import { FacebookShareButton, TwitterShareButton } from 'react-share'

class ShareModal extends Component {
  render() {
    const { shareModalOpen, url, closeModal } = this.props

    return (
      <div className={`share-modal ${shareModalOpen ? "modal-on" : ""}`}>
        <h4>Share this episode</h4>
        <div className="share__url__box">
          <p className="share__url">{`lite.dailyaudiobible.com${url}`}</p>
        </div>
        <div className="share__social">
          <FacebookShareButton className="mdi mdi-facebook" url={`https://lite.dailyaudiobible.com${url}`}></FacebookShareButton>
          <TwitterShareButton className="mdi mdi-twitter" url={`https://lite.dailyaudiobible.com${url}`}></TwitterShareButton>
        </div>
        <button className="mdi mdi-close" onClick={closeModal}></button>
      </div>
    )
  }
}

export default ShareModal
